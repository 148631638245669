<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-data-table :headers="headers" :items="getFees" :search="search">
        <template v-slot:[`item.name`]="{ item }">
          <router-link
            :to="{
              path: '/configure/fees/edit/' + item.feeId,
            }"
          >
            {{ item.name }}
          </router-link>
        </template>
        <template v-slot:[`item.ACTIONS`]="{ item }">
          <v-icon @click="deleteRecord(item)">delete</v-icon>
        </template>
        <template v-slot:[`item.updated_at`]="{ item }">
          {{ item.updated_at | moment(campus.date_format) }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: this.$i18n.t("FEE.NAME"), value: "name" },
        { text: this.$i18n.t("FEE.TYPE"), value: "feeTypeName" },
        { text: this.$i18n.t("FEE.UPDATE_AT"), value: "updated_at" },
      ],
    };
  },
  methods: {
    ...mapActions(["getListFees", "feeTypes"]),
  },
  mounted() {
    this.getListFees();
  },
  computed: {
    ...mapGetters(["getFees", "campus"]),
  },
};
</script>

<style></style>
