<template>
  <v-main>
    <v-row gutters="2" class="pt-0 pb-0 mb-2" justify="end">
      <v-col cols="12">
        <Buttons
          :btnCancel="false"
          :textSave="$t('GENERAL.NEW')"
          v-on:save="addFees"
        />
      </v-col>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-text>
            <TableFee></TableFee>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TableFee from "@/view/content/components/fees/Table";
import Buttons from "@/view/content/components/Buttons";

export default {
  name: "fees",
  components: { TableFee, Buttons },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Fees" }]);
  },
  methods: {
    addFees() {
      this.$router.push({
        name: "fees-form",
        params: { operation: "new" },
      });
    },
  },
};
</script>
